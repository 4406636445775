import * as React from 'react'
import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Processes from '@rushplay/processes'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Player from '../player'
import * as ServerConfiguration from '../server-configuration'
import * as Icons from '../icons'
import { DisabledSelectField } from '../disabled-select-field'
import { FieldBuilder } from '../field-builder'
import { HtmlContent } from '../html-content'
import { InputPasswordField } from '../input-password-field'
import { PhoneVerificationCodeInput } from '../phone-verification-code-input'
import { RegistrationStep } from '../constants'
import { SelectField } from '../select-field'
import { Spinner } from '../spinner'
import { SubmitButton } from '../submit-button'

import * as uiSchema from './ui-schema'

export function RegistrationForm(props) {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    dispatch(Player.updateTrafficJunky({ event: 'REGISTRATION_FUNNEL' }))
  }, [])

  const { country, brand } = ServerConfiguration.useContext()

  const loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: ['PHONE_VERIFICATION_LOADING'],
    })
  )

  if (!country.enabled) {
    return (
      <React.Fragment>
        <Common.Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          pt="2"
        >
          <Common.Box fontSize={8} color="warning">
            <Icons.Warning />
          </Common.Box>
          <Common.Box fontSize={3} p={1}>
            {i18n.translate('errors.unsupported-country')}
          </Common.Box>
        </Common.Box>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {props.step === RegistrationStep.Credentials && (
        <React.Fragment>
          <FieldBuilder
            uiSchema={uiSchema.step1}
            name="registration-step1"
            initialValues={{
              '#/properties/countryCallingCode': R.toString(
                country.countryCode
              ),
            }}
            getCustomField={scope => {
              switch (scope) {
                case '#/properties/password': {
                  return InputPasswordField
                }

                default: {
                  return null
                }
              }
            }}
          />
          {R.not(R.isEmpty(props.errors)) && (
            <Common.Box display="grid" gridGap={1} pt={1}>
              {R.map(error => {
                return (
                  <Common.Box key={error} color="danger" fontSize={1}>
                    {i18n.translate(`errors.registration.${error}`)}
                  </Common.Box>
                )
              }, props.errors)}
            </Common.Box>
          )}
          <Common.Box pt={2} textAlign="center">
            <SubmitButton
              onlyScopes={[
                '#/properties/email',
                '#/properties/password',
                '#/properties/phonenumber',
              ]}
              loading={loading}
            >
              {loading ? (
                <Common.Box
                  maxWidth="30px"
                  maxHeight="30px"
                  mt="-15px"
                  mb="5px"
                >
                  <Spinner delay />
                </Common.Box>
              ) : (
                i18n.translate('register.step1.cta')
              )}
            </SubmitButton>
          </Common.Box>
        </React.Fragment>
      )}

      {props.step === RegistrationStep.Identity && (
        <React.Fragment>
          <FieldBuilder
            uiSchema={uiSchema.step2}
            name="registration-step2"
            initialValues={{
              '#/properties/country': country.name,
            }}
            getCustomField={scope => {
              switch (scope) {
                case '#/properties/country': {
                  if (brand === 'playboom' && country.alpha2 === 'CA') {
                    return DisabledSelectField
                  } else {
                    return SelectField
                  }
                }

                case '#/properties/phoneVerificationCode': {
                  return PhoneVerificationCodeInput
                }

                default: {
                  return null
                }
              }
            }}
          />
          {R.not(R.isEmpty(props.errors)) && (
            <Common.Box display="grid" gridGap={1} pt={1}>
              {R.map(error => {
                return (
                  <Common.Box key={error} color="danger" fontSize={1}>
                    {i18n.translate(`errors.registration.${error}`)}
                  </Common.Box>
                )
              }, props.errors)}
            </Common.Box>
          )}
          <Common.Box fontSize={1} pt={1}>
            <HtmlContent
              html={{
                __html: i18n.translate(
                  'register.step2.terms-and-privacy-agreement'
                ),
              }}
            />
          </Common.Box>
          <Common.Box pt={2} textAlign="center">
            <SubmitButton>{i18n.translate('register.step2.cta')}</SubmitButton>
          </Common.Box>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

RegistrationForm.propTypes = {
  errors: PropTypes.array,
  step: PropTypes.number.isRequired,
}
