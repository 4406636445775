import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import * as forms from '@rushplay/forms'
import { useI18n } from '@rushplay/i18n'

import { FieldLabel } from './field-label'
import { FieldResponse } from './field-response'
import { Select } from './select'

export function DisabledSelectField(props) {
  const i18n = useI18n()

  const field = forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <common.Box display="flex" flexBasis="100%" flexDirection="column">
      {i18n.hasTranslation(field.label) && (
        <FieldLabel disabled>{i18n.translate(field.label)}</FieldLabel>
      )}
      <Select
        autoFocus={props.autoFocus}
        contentTranslated={props.contentTranslated}
        autoComplete={props.autoComplete}
        disabled
        name={field.name}
        options={props.options}
        valid={R.isEmpty(field.errors)}
        visited={
          !R.includes(field.status, [
            forms.FieldStatus.PRISTINE,
            forms.FieldStatus.ABSENT,
          ])
        }
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
      />
      <FieldResponse scope={props.scope} />
    </common.Box>
  )
}

DisabledSelectField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  contentTranslated: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  scope: PropTypes.string,
}
