import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Herz from '@rushplay/herz'
import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'

import * as ServerConfiguration from '../server-configuration'
import { ProgressBar } from '../progress-bar'
import { QueryDrawer } from '../query-drawer'
import { RegistrationForm } from '../registration-form-playboom24'
import { RegistrationStep } from '../constants'

import { dataSchema } from './data-schema'

export function RegistrationDrawer(props) {
  const i18n = I18n.useI18n()
  const fingerprint = Herz.Seon.useFingerprint()
  const [step, setStep] = React.useState(RegistrationStep.Credentials)
  const [codeSentTo, setCodeSentTo] = React.useState(null)
  const [errors, setErrors] = React.useState([])

  const { countries, locale } = ServerConfiguration.useContext()

  const schema = React.useMemo(
    () =>
      dataSchema({
        countryCallingCode: R.map(
          R.toString,
          R.pluck('countryCode', countries)
        ),
        country: R.pluck('name', countries),
        maxYear: new Date(Date.now()).getFullYear() - 18,
        shouldVerifyPhone: props.shouldVerifyPhone,
      }),
    [props.shouldVerifyPhone, countries]
  )

  function handleSubmit(data) {
    const userData = {
      affiliateClickId: props.affiliateClickId,
      affiliateSubId: props.affiliateSubId,
      allowEmail: data.promotional,
      allowSms: data.promotional,
      birthdate: `${data.bdayDay}/${data.bdayMonth}/${data.bdayYear}`,
      city: data.city,
      clientType: props.clientType,
      countryCallingCode: `+${data.countryCallingCode}`,
      countryCode: R.path(
        ['alpha2'],
        R.find(R.propEq('name', data.country), countries)
      ),
      currency: props.currency,
      email: data.email,
      firstName: data.firstName.trim(),
      // Hardcodes gender because it's required on BE, but we don't care
      gender: 'Male',
      generate_username: true,
      language: locale.language,
      lastName: data.lastName.trim(),
      mobile: data.phonenumber,
      netrefererBTag: props.btag,
      password: data.password,
      passwordConfirmation: data.password,
      phoneVerificationCode: data.phoneVerificationCode,
      street: data.street,
      utmCampaign: props.utmCampaign,
      utmMedium: props.utmMedium,
      utmSource: props.utmSource,
      zip: data.zip,
      seon_session: fingerprint.value,
    }

    props.onSubmit(userData, setErrors)
  }

  function handlePhoneValidation(data) {
    const userData = {
      countryCallingCode: `+${data.countryCallingCode}`,
      phone: data.phonenumber,
      language: locale.language,
    }

    function failure(errors) {
      setErrors(errors)
      setCodeSentTo('')
    }

    function success() {
      setErrors([])
      setStep(RegistrationStep.Identity)
      setCodeSentTo(`${data.countryCallingCode}${data.phonenumber}`)
    }

    function validationSuccess() {
      if (props.shouldVerifyPhone) {
        props.onPhoneVerificationCodeRequest(userData, failure, success)
      } else {
        success()
      }
    }

    props.onPhoneValidation(userData, failure, validationSuccess)
  }

  if (fingerprint.fetching) {
    return null
  }

  return (
    <Forms.Provider
      name="register"
      schema={schema}
      onSubmit={(formErrors, data) => {
        if (R.isEmpty(formErrors) && step > RegistrationStep.Credentials) {
          handleSubmit(data)
        }

        if (
          R.not(
            R.equals(
              codeSentTo,
              `${data.countryCallingCode}${data.phonenumber}`
            )
          )
        ) {
          props.onRegistrationStarted()
          handlePhoneValidation(data)
        }

        if (
          data.email &&
          data.password &&
          data.phonenumber &&
          data.countryCallingCode &&
          codeSentTo &&
          R.equals(codeSentTo, `${data.countryCallingCode}${data.phonenumber}`)
        ) {
          setStep(RegistrationStep.Identity)
        }
      }}
    >
      <QueryDrawer
        onSecondaryAction={
          step > RegistrationStep.Credentials
            ? () => setStep(RegistrationStep.Credentials)
            : null
        }
        activeQueryName="register"
        title={i18n.translate('register.title')}
      >
        <Common.Box pb={6} color="g-text">
          <ProgressBar step={step} steps={2} />
          <RegistrationForm errors={errors} step={step} />
        </Common.Box>
      </QueryDrawer>
    </Forms.Provider>
  )
}

RegistrationDrawer.propTypes = {
  affiliateClickId: PropTypes.string,
  affiliateSubId: PropTypes.string,
  btag: PropTypes.string,
  clientType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  shouldVerifyPhone: PropTypes.bool,
  utmCampaign: PropTypes.string,
  utmMedium: PropTypes.string,
  utmSource: PropTypes.string,
  onPhoneVerificationCodeRequest: PropTypes.func.isRequired,
  onPhoneValidation: PropTypes.func.isRequired,
  onRegistrationStarted: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
